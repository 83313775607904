import React, { useEffect } from "react";
import Images from "../../helpers/Images";
import { Button, Container, Row } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AOS from "aos";

const About = () => {
  useEffect(() => {
    AOS.init({
      disable: window.innerWidth < 768,
    });
  }, []);
  const navigate = useNavigate();
  return (
    <div className="home-about-us-wrapper">
      <Container>
        <Row>
          <div className="home-about-us-inner">
            <div className="home-about-left" data-aos="fade-up">
              <img src={Images.hAbout} alt="about-img" className="img-fluid" />
            </div>
            <div className="home-about-right" data-aos="fade-up">
              <div className="home-about-right-head">
                <p className="lightblueBox">About Us</p>
                <h2>We Are Increasing Business Success With Technology</h2>
              </div>

              <p className="upper-text">
                Over 25 years working in IT services developing software
                applications and mobile apps for clients all over the world.
              </p>
              <p>
                At Amogh, we're dedicated to leveraging technology
                to drive business success. With innovative solutions and
                strategic insights, we empower organizations to thrive in
                today's dynamic marketplace. From software development to
                digital marketing, we're committed to helping our clients stay
                ahead and achieve their goals through collaboration, integrity,
                and customer satisfaction.
              </p>
              <Button
                className="defaultBtn"
                variant="outline-primary"
                onClick={() => navigate("/about-us")}
              >
                Learn More <FaAngleRight />
              </Button>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default About;
