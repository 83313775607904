import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Images from "../../helpers/Images";
import Aos from "aos";

const WhyChooseUs = () => {
  useEffect(() => {
    Aos.init({});
  }, []);
  return (
    <div className="whyChoose-wrapper">
      <Container>
        <div className="whyChoose-inner">
          <div
            className="heading-part"
            data-aos="fade-up"
            data-aos-delay="120"
            data-aos-duration="1000"
          >
            <p className="lightblueBox">Why Choose Us</p>
            <h2 className="title">We provide truly prominent IT solutions.</h2>
          </div>
          <div
            className="whyChooseBox-wrapper"
            data-aos="zoom-in-down"
            data-aos-delay="120"
            data-aos-duration="1000"
          >
            <div className="whyChooseBox">
              <div className="img-box">
                <img src={Images.choose1} alt="whychoose" />
              </div>
              <h3>All Technology</h3>
              <p>
                Expertly leveraging diverse technologies to drive business
                growth and transformation.
              </p>
            </div>
            <div className="whyChooseBox">
              <div className="img-box">
                <img src={Images.choose2} alt="whychoose" />
              </div>
              <h3>Professional Team</h3>
              <p>
                Experienced professionals dedicated to delivering excellence in
                every project.
              </p>
            </div>
            <div className="whyChooseBox">
              <div className="img-box">
                <img src={Images.choose3} alt="whychoose" />
              </div>
              <h3>Security Management </h3>
              <p>
                Ensuring robust IT security, safeguarding your assets with
                expert management.
              </p>
            </div>
            <div className="whyChooseBox">
              <div className="img-box">
                <img src={Images.choose4} alt="whychoose" />
              </div>
              <h3>Affordable cost</h3>
              <p>
                Providing quality solutions at affordable prices, ensuring value
                for clients.
              </p>
            </div>
            <div className="whyChooseBox">
              <div className="img-box">
                <img src={Images.choose5} alt="whychoose" />
              </div>
              <h3>On-Time Delivery</h3>
              <p>
                Timely delivery assured, meeting deadlines with precision every time.
              </p>
            </div>
            <div className="whyChooseBox">
              <div className="img-box">
                <img src={Images.choose6} alt="whychoose" />
              </div>
              <h3>Innovation Process</h3>
              <p>
                Innovating with a structured process, ensuring creativity and
                efficiency in solutions.
              </p>
            </div>
            <div className="whyChooseBox">
              <div className="img-box">
                <img src={Images.choose7} alt="whychoose" />
              </div>
              <h3>IT Managment</h3>
              <p>
                Efficiently managing IT systems, optimizing performance and
                security for businesses.
              </p>
            </div>
            <div className="whyChooseBox">
              <div className="img-box">
                <img src={Images.choose8} alt="whychoose" />
              </div>
              <h3>Data Managment</h3>
              <p>
                Efficiently managing data operations for optimal security and
                accessibility.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default WhyChooseUs;
