import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Images from "../../helpers/Images";
import Aos from "aos";

const Technology = () => {
  useEffect(() => {
    Aos.init({ disable: window.innerWidth < 768,});
  }, []);
  return (
    <div className="tech-wrapper">
      <div className="tech-inner">
        <Container>
          <Row>
            <div
              className="tech-head"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <p className="lightblueBox">Technologies</p>
              <h2 className="title">
                What Technology We Are Using <br />
                For Our Valued Customers
              </h2>
            </div>
            <div
              className="tech-box-wrapper"
              data-aos="flip-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <div className="tech-box">
                <div className="tech-logo-box">
                  <div className="logo-img">
                    <img className="main-img" src={Images.techW1} alt="tech" />
                    <img className="hover-img" src={Images.tech1} alt="tech" />
                  </div>
                </div>
                <div className="tech-title">
                  <h4>NODE JS</h4>
                </div>
              </div>
              <div className="tech-box">
                <div className="tech-logo-box">
                  <div className="logo-img">
                    <img className="main-img" src={Images.techW2} alt="tech" />
                    <img className="hover-img" src={Images.tech2} alt="tech" />
                  </div>
                </div>
                <div className="tech-title">
                  <h4>PYTHON</h4>
                </div>
              </div>
              <div className="tech-box">
                <div className="tech-logo-box">
                  <div className="logo-img">
                    <img className="main-img" src={Images.techW3} alt="tech" />
                    <img className="hover-img" src={Images.tech3} alt="tech" />
                  </div>
                </div>
                <div className="tech-title">
                  <h4>MY SQL</h4>
                </div>
              </div>
              <div className="tech-box">
                <div className="tech-logo-box">
                  <div className="logo-img">
                    <img className="main-img" src={Images.techW4} alt="tech" />
                    <img className="hover-img" src={Images.tech4} alt="tech" />
                  </div>
                </div>
                <div className="tech-title">
                  <h4>REACT JS</h4>
                </div>
              </div>
              <div className="tech-box">
                <div className="tech-logo-box">
                  <div className="logo-img">
                    <img className="main-img" src={Images.techW5} alt="tech" />
                    <img
                      className="hover-img"
                      style={{ width: "107px", height: "33px" }}
                      src={Images.tech5}
                      alt="tech"
                    />
                  </div>
                </div>
                <div className="tech-title">
                  <h4>FIREBASE</h4>
                </div>
              </div>
              <div className="tech-box">
                <div className="tech-logo-box">
                  <div className="logo-img">
                    <img className="main-img" src={Images.techW6} alt="tech" />
                    <img className="hover-img" src={Images.tech6} alt="tech" />
                  </div>
                </div>
                <div className="tech-title">
                  <h4>DoCKER</h4>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Technology;
