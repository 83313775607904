import React, { useRef, useEffect } from "react";
import CountUp from "react-countup";
import useOnScreenHook from "../../helpers/useOnScreenHook";
import { Container, Row } from "react-bootstrap";
import Images from "../../helpers/Images";
import Aos from "aos";

const Counter = () => {
  useEffect(() => {
    Aos.init({ disable: window.innerWidth < 768});
  }, []);
  const counterref = useRef(null);
  const isVisible = useOnScreenHook(counterref);
  return (
    <div className="counter-wrapper">
      <div className="counter-innner">
        <Container>
          <Row ref={counterref}>
            <div
              className="counter-box-wrapper"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              <div className="counter-box">
                <div className="counter-img">
                  <img src={Images.count1} alt="counter" />
                </div>
                <div className="counter-content">
                  <h5> {isVisible && <CountUp delay={0} end={100} />}+ </h5>
                  <p>Happy Clients</p>
                </div>
              </div>
              <div className="counter-box">
                <div className="counter-img">
                  <img src={Images.count2} alt="counter" />
                </div>
                <div className="counter-content">
                  <h5> {isVisible && <CountUp delay={0} end={8} />}+ </h5>
                  <p>Years Experience</p>
                </div>
              </div>
              <div className="counter-box">
                <div className="counter-img">
                  <img src={Images.count3} alt="counter" />
                </div>
                <div className="counter-content">
                  <h5> {isVisible && <CountUp delay={0} end={100} />}+ </h5>
                  <p>Projects</p>
                </div>
              </div>
              <div className="counter-box">
                <div className="counter-img">
                  <img src={Images.count4} alt="counter" />
                </div>
                <div className="counter-content">
                  <h5> {isVisible && <CountUp delay={0} end={30} />}+ </h5>
                  <p>Expert Engineers</p>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Counter;
