import React, { useEffect } from "react";
import Header from "../../components/Header";
import Back from "../../components/Back";
import Footer from "../../components/Footer";
import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Images from "../../helpers/Images";
import WorkProcess from "../../components/WorkProcess";
import Aos from "aos";

const ServicesDetail = () => {
  const location = useLocation();
  const selectedService = location?.state;
  useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <>
      <Header />
      <Back title={"Services"} />
      <div className="serviceDetail-wrapper">
        <Container>
          <Row>
            <div className="serviceDetail-inner">
              <div
                className="left-section"
                data-aos="fade-right"
                data-aos-delay="120"
                data-aos-duration="1000"
              >
                <h2>{selectedService?.title}</h2>
                <p>{selectedService?.desc}</p>
              </div>
              <div
                className="right-section"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img src={`${process.env.PUBLIC_URL}/${selectedService?.imgLarge}`} alt="services" />
              </div>
            </div>
          </Row>
          <Row>
            <div className="language-section" data-aos="fade-up"
                data-aos-delay="120"
                data-aos-duration="1000">
              <h5>Solutions We Provide</h5>
              <div className="language-wrapper">
                {selectedService?.languages?.map((item, i) => (
                  <div key={i} className="language-box">
                    <span>
                      <img src={Images.badge} alt="icon" />
                    </span>
                    <p>{item.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </Row>
        </Container>
        <WorkProcess />
      </div>
      <Footer />
    </>
  );
};

export default ServicesDetail;
