import { Button, Container, Row } from "react-bootstrap";
import React, { useEffect } from "react";
import { FaAngleRight } from "react-icons/fa";
import Aos from "aos";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  useEffect(() => {
    Aos.init({ disable: window.innerWidth < 768,});
  }, []);
  const navigate = useNavigate();
  return (
    <div className="banner-wrapper">
      <Container>
        <Row>
          <div className="banner-txt-wrapper">
            <p data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
              TECHNOLOGY & IT SOLUTIONS
            </p>
            <h2 data-aos="fade-up" data-aos-delay="80" data-aos-duration="1000">
              Excellent IT services for your success
            </h2>
            <h6
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
            >
              We are Leading Technology Solutions Providing Company all over the
              world doing over 30 years.
            </h6>
            <Button
              variant="outline-primary"
              className="header-btn"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1000"
              onClick={() => navigate('/services')}
            >
              Discover More <FaAngleRight />
            </Button>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
