import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTopRoute = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (pathname !== "/") window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, [pathname]);
  return null;
};

export default ScrollToTopRoute;
