import Logo from "../assets/images/logo/logo.png";
import banner from "../assets/images/banner.png";
import ser1 from "../assets/images/home/ser-1.png";
import ser2 from "../assets/images/home/ser-2.png";
import ser3 from "../assets/images/home/ser-3.png";
import ser4 from "../assets/images/home/ser-4.png";
import ser5 from "../assets/images/home/ser-5.png";
import ser6 from "../assets/images/home/ser-6.png";
import ser7 from "../assets/images/home/ser-7.png";
import hAbout from "../assets/images/home/about.png";
import techBg from "../assets/images/home/tech-bg.jpg";
import tech1 from "../assets/images/home/node.png";
import techW1 from "../assets/images/home/nodeW.png";
import tech2 from "../assets/images/home/python.png";
import techW2 from "../assets/images/home/pythonWhite.png";
import tech3 from "../assets/images/home/mysql.png";
import techW3 from "../assets/images/home/mySqlW.png";
import tech4 from "../assets/images/home/reactjs.png";
import techW4 from "../assets/images/home/reactW.png";
import tech5 from "../assets/images/home/firebase.png";
import techW5 from "../assets/images/home/firebaseW.png";
import tech6 from "../assets/images/home/docker.png";
import techW6 from "../assets/images/home/dockerw.png";
import gallery1 from "../assets/images/home/tab1.jpg";
import gallery2 from "../assets/images/home/tab2.jpg";
import gallery3 from "../assets/images/home/tab3.jpg";
import gallery4 from "../assets/images/home/tab4.jpg";
import gallery5 from "../assets/images/home/tab5.jpg";
import gallery6 from "../assets/images/home/tab6.jpg";
import arrowNext from "../assets/images/home/arrow-next.png";
import arrowPre from "../assets/images/home/arrow-pre.png";
import count1 from "../assets/images/home/counter1.png";
import count2 from "../assets/images/home/counter2.png";
import count3 from "../assets/images/home/counter3.png";
import count4 from "../assets/images/home/counter4.png";
import hContact from "../assets/images/home/contactUs.png";
import about1 from "../assets/images/aboutUs/about1.png";
import whatsection1 from "../assets/images/aboutUs/what1.png";
import whatsection2 from "../assets/images/aboutUs/what2.png";
import whatsection3 from "../assets/images/aboutUs/what3.png";
import choose1 from "../assets/images/aboutUs/choose1.png";
import choose2 from "../assets/images/aboutUs/choose2.png";
import choose3 from "../assets/images/aboutUs/choose3.png";
import choose4 from "../assets/images/aboutUs/choose4.png";
import choose5 from "../assets/images/aboutUs/choose5.png";
import choose6 from "../assets/images/aboutUs/choose6.png";
import choose7 from "../assets/images/aboutUs/choose7.png";
import choose8 from "../assets/images/aboutUs/choose8.png";
import serviceBg from "../assets/images/services/services_banner.png";
import aboutBg from "../assets/images/aboutUs/aboutBg.png";
import portfolioBg from "../assets/images/portfolio/portfolio.png";
import careerBg from "../assets/images/career/career.png";
import contactUsBg from "../assets/images/contactUs/contact_banner.png";
import webBanner from "../assets/images/services/services_banner.png";
import mobileBanner from "../assets/images/services/services_banner.png";
import softwareBanner from "../assets/images/services/services_banner.png";
import digitalMBanner from "../assets/images/services/services_banner.png";
import devOpsBanner from "../assets/images/services/services_banner.png";
import crmBanner from "../assets/images/services/services_banner.png";
import service1 from "../assets/images/services/service1.png";
import service2 from "../assets/images/services/service2.png";
import service3 from "../assets/images/services/service3.png";
import service4 from "../assets/images/services/service4.png";
import service5 from "../assets/images/services/service5.png";
import service6 from "../assets/images/services/service6.png";
import serviceW1 from "../assets/images/services/serviceW1.png";
import serviceW2 from "../assets/images/services/serviceW2.png";
import serviceW3 from "../assets/images/services/serviceW3.png";
import serviceW4 from "../assets/images/services/serviceW4.png";
import badge from "../assets/images/services/badge.png";
import softwareBg from "../assets/images/services/software.png";
import webBg from "../assets/images/services/web_development.png";
import mobileBg from "../assets/images/services/app_development.png";
import blockChainBg from "../assets/images/services/blockchain.png";
import productBg from "../assets/images/services/it_maintenance.png";
import dataBg from "../assets/images/services/devops.png";

const Images = {
  badge,
  service1,
  service2,
  service3,
  service4,
  service5,
  service6,
  serviceW3,
  serviceW2,
  serviceW1,
  serviceW4,
  webBanner,
  mobileBanner,
  softwareBanner,
  digitalMBanner,
  devOpsBanner,
  crmBanner,
  aboutBg,
  careerBg,
  portfolioBg,
  contactUsBg,
  serviceBg,
  softwareBg,
  webBg,
  mobileBg,
  blockChainBg,
  productBg,
  dataBg,
  Logo,
  banner,
  ser1,
  ser2,
  ser3,
  ser4,
  ser5,
  ser6,
  ser7,
  hAbout,
  techBg,
  tech1,
  techW1,
  tech2,
  techW2,
  tech3,
  techW3,
  tech4,
  techW4,
  tech5,
  techW5,
  tech6,
  techW6,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  arrowNext,
  arrowPre,
  count1,
  count2,
  count3,
  count4,
  hContact,
  about1,
  whatsection1,
  whatsection2,
  whatsection3,
  choose1,
  choose2,
  choose3,
  choose4,
  choose5,
  choose6,
  choose7,
  choose8,
};
export default Images;
