import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs/index";
import ContactUs from "./pages/ContactUs";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Career from "./pages/Career";
import ServicesDetail from "./pages/Services/ServicesDetail";
const AppRoutes = () => {
  return (
    <BrowserRouter >
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/:path/:serviceName" element={<ServicesDetail />} />        
      </Routes>
    </BrowserRouter>
  );
};
export default AppRoutes;
