import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Back from "../../components/Back";
import WhoWeAre from "./WhoWeAre";
import WhatWeDo from "./WhatWeDo";
import WhyChooseUs from "./WhyChooseUs";
import ContactUs from "../Home/ContactUs";
import CommonBlueBox from "../../components/CommonBlueBox";
import SeoTags from "../../components/SeoTags";

const AboutUs = () => {
 
  return (
    <>
      <SeoTags title = 'Amogh | About Us' description="This is my description"/>
      <Header />
      <Back title={"About Us"} />
      <div className="about-wappper">
        <WhoWeAre/>
        <WhatWeDo/>
        <WhyChooseUs/>
        <CommonBlueBox/>
        <ContactUs/>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
