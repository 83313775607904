import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Images from "../helpers/Images";
import {
  FaFacebookF,
  FaXTwitter,
  FaPinterestP,
  FaInstagram,
  FaLocationDot,
  FaPhone,
  FaPaperPlane,
} from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa";
import Aos from "aos";
import DetailsData from "../../src/helpers/ServiceDetails.json";

const Footer = () => {
  useEffect(() => {
    Aos.init({ disable: window.innerWidth < 768,});
  }, []);
  return (
    <div className="footer-wrapper">
      <div className="footer-top">
        <Container>
          <Row>
            <div
              className="col-lg-3 col-md-12 col-sm-12 footer-logo-box"
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="1000"
            >
              <div>
                <Link to="/">
                  <img
                    loading="lazy"
                    src={Images.Logo}
                    className="f-logo-img"
                    alt=""
                  />
                </Link>
              </div>
              <div>
                <p className="footer-text">
                we empower businesses with cutting-edge technology solutions to thrive in the digital era.
                </p>
              </div>
              <ul className="footer-social">
                <li>
                  <FaFacebookF />
                </li>
                <li>
                  <FaXTwitter />
                </li>
                <li>
                  <FaPinterestP />
                </li>
                <li>
                  <FaInstagram />
                </li>
              </ul>
            </div>
            <div
              className="col-lg-3 col-md-4 col-sm-12 pl-45 md-pl-15 md-mb-30 footer-nav"
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="1000"
            >
              <h4>Company</h4>
              <ul className="footer-link">
                <Link to="/about-us">
                  <li>About Us</li>
                </Link>
                <Link to="/portfolio">
                  <li>Portfolio</li>
                </Link>
                <Link to="/career">
                  <li>Careers</li>
                </Link>
                <Link to="/contact-us">
                  <li>Contact Us</li>
                </Link>
              </ul>
            </div>
            <div
              className="col-lg-3 col-md-4 col-sm-12 pl-45 md-pl-15 md-mb-30 footer-nav"
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="1000"
            >
              <h4>Services</h4>
              <ul className="footer-link">
                {/* <Link to="/services/software-development">
                  <li>Software Development</li>
                </Link>
                <Link to="/services/web-development">
                  <li>Web Development</li>
                </Link>
                <Link to="/services/app-development">
                  <li>App Development</li>
                </Link>
                <Link to={`/services/${item.key}`} state={item}>
                  <li>Blockchain & Ai Development</li>
                </Link>
                <Link to="/services/product-design">
                  <li>Product Design</li>
                </Link> */}
                {DetailsData?.map((item, i) => (
                <Link to={`/services/${item.key}`} state={item}>
                  <li>{item.title}</li>
                </Link>
               ))}
              </ul>
            </div>
            <div
              className="col-lg-3 col-md-4 col-sm-12 md-mb-30 footer-nav"
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="1000"
            >
              <h4>Contact US</h4>
              <ul className="footer-link">
                <li className="footer-address">
                  <i>
                    <FaLocationDot />
                  </i>
                  <p>107, Navjeevan Chambers, C-Scheme, Jaipur</p>
                </li>
                <li className="footer-address">
                  <i>
                    <FaPhone />
                  </i>
                  <div className="phone-number">
                  <a href="tel:+44 7796 002487">+44 7796 002487,</a>
                  <a href="tel:+91 9829161718">+91 9829161718</a>
                  </div>                                   
                </li>
                <li className="footer-address">
                  <i>
                    <FaEnvelope />
                  </i>
                  <a href="mailto:sarvesh@amogh.co.uk">
                  sarvesh@amogh.co.uk
                  </a>
                </li>
                <li className="footer-address">
                  <input
                    type="email"
                    name="Email"
                    placeholder="Your email address"
                  />
                  <em className="paper-plane">
                    <input type="submit" />
                    <i>
                      <FaPaperPlane />
                    </i>
                  </em>
                </li>
              </ul>
            </div>
          </Row>
          <Row>
            <div className="footer-bottom">
              <ul className="footer-social">
                <li>
                  <FaFacebookF />
                </li>
                <li>
                  <FaXTwitter />
                </li>
                <li>
                  <FaPinterestP />
                </li>
                <li>
                  <FaInstagram />
                </li>
              </ul>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default Footer;
