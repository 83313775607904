import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import Images from "../helpers/Images";
import { useLocation, useNavigate } from "react-router-dom";
import ScrollToTopRoute from "./ScrollToTopRoute";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaFacebookF,
  FaXTwitter,
  FaPinterestP,
  FaInstagram,
} from "react-icons/fa6";

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeLinkName, setActiveLinkName] = useState(pathname);
  const handleNavigation = (routeName) => {
    setActiveLinkName(routeName);
    navigate(routeName);
  };
  return (
    <>
      <div className="header-wrapper">
        <ScrollToTopRoute />
        <div className="custom-header">
          <Container>
            <Navbar expand="lg">
              <Navbar.Brand to="/">
                <img
                  src={Images.Logo}
                  className="header-logo"
                  alt="Logo"
                  onClick={() => {
                    handleNavigation("/");
                  }}
                />
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Offcanvas placement="start">
                <Offcanvas.Header closeButton>
                  <Navbar.Brand to="/">
                    <img
                      src={Images.Logo}
                      className="header-logo "
                      alt="Logo"
                      onClick={() => {
                        handleNavigation("/");
                      }}
                    />
                  </Navbar.Brand>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link
                      to="/"
                      className={activeLinkName === "/" ? "active" : ""}
                      onClick={() => {
                        handleNavigation("/");
                      }}
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      className={activeLinkName === "/about-us" ? "active" : ""}
                      onClick={() => {
                        handleNavigation("/about-us");
                      }}
                      to="/about-us"
                    >
                      About
                    </Nav.Link>
                    <Nav.Link
                      className={activeLinkName === "/services" ? "active" : ""}
                      onClick={() => {
                        handleNavigation("/services");
                      }}
                      to="/services"
                    >
                      Services
                    </Nav.Link>
                    <Nav.Link
                      className={
                        activeLinkName === "/portfolio" ? "active" : ""
                      }
                      onClick={() => {
                        handleNavigation("/portfolio");
                      }}
                      to="/portfolio"
                    >
                      Portfolio
                    </Nav.Link>
                    <Nav.Link
                      className={activeLinkName === "/career" ? "active" : ""}
                      onClick={() => {
                        handleNavigation("/career");
                      }}
                      to="/career"
                    >
                      Careers
                    </Nav.Link>
                    <Nav.Link
                      className={
                        activeLinkName === "/contact-us" ? "active" : ""
                      }
                      onClick={() => {
                        handleNavigation("/contact-us");
                      }}
                      to="/contact-us"
                    >
                      Contact Us
                    </Nav.Link>
                  </Nav>
                  <Nav className="header-contact-side">
                    <Button
                      onClick={() => {
                        handleNavigation("/contact-us");
                      }}
                      variant="outline-primary"
                      className="defaultBtn header-btn"
                    >
                      Get a Quote
                    </Button>
                    <div className="mobile-menu-address">
                      <h5>Get In Touch</h5>
                      <address>
                      107, Navjeevan Chambers, C-Scheme, Jaipur
                      </address>
                      <ul className="footer-social">
                        <li>
                          <FaFacebookF />
                        </li>
                        <li>
                          <FaXTwitter />
                        </li>
                        <li>
                          <FaPinterestP />
                        </li>
                        <li>
                          <FaInstagram />
                        </li>
                      </ul>
                    </div>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Navbar>
          </Container>
        </div>
      </div>
    </>
  );
};
export default Header;
