import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Back from "../../components/Back";
import ServiceCard from "./ServiceCard";
import WorkProcess from "../../components/WorkProcess";

const Services = () => {
  useEffect(() => {
    document.title = 'Amogh | Services'
      },[]);
  return (
    <>
    <Header/>
    <Back title={"Services"} />
    <div className="service-wrapper">
      <ServiceCard/>
      <WorkProcess/>
    </div>
    <Footer/>
    </>
   
  );
};

export default Services;
