import React from "react";
import { useLocation } from "react-router-dom";
import Images from "../helpers/Images";

const Back = ({ title }) => {
  const location = useLocation();
  const getPathAndBackground = () => {
    // const key =  location.pathname.split("/")[1];
    // console.log("chabbiiiiiii",location.pathname);
    let returnData = {};
    switch (location.pathname) {
      case "/about-us":
        returnData = {
          bgImage: Images.aboutBg,
          path: `${location.pathname}`,
        };
        break;
      case "/services":
        returnData = {
          bgImage: Images.serviceBg,
          path: `${location.pathname}`,
        };
        break;
      case "/portfolio":
        returnData = {
          bgImage: Images.portfolioBg,
          path: `${location.pathname}`,
        };
        break;
      case "/career":
        returnData = {
          bgImage: Images.careerBg,
          path: `${location.pathname}`,
        };
        break;
      case "/contact-us":
        returnData = {
          bgImage: Images.contactUsBg,
          path: `${location.pathname}`,
        };
        break;
      case `/services/software-development`:
        returnData = {
          bgImage: Images.softwareBg,
          path: `${location.pathname}`,
        };
        break;
      case `/services/web-development`:
        returnData = {
          bgImage: Images.webBg,
          path: `${location.pathname}`,
        };
        break;
      case `/services/app-development`:
        returnData = {
          bgImage: Images.mobileBg,
          path: `${location.pathname}`,
        };
        break;
      case `/services/blockchain-development`:
        returnData = {
          bgImage: Images.blockChainBg,
          path: `${location.pathname}`,
        };
        break;
      case `/services/product-design`:
        returnData = {
          bgImage: Images.productBg,
          path: `${location.pathname}`,
        };
        break;
      case `/services/database-administration`:
        returnData = {
          bgImage: Images.dataBg,
          path: `${location.pathname}`,
        };
        break;

      default:
        break;
    }
    return returnData;
  };
  return (
    <div>
      {/* {console.log(getPathAndBackground().bgImage)} */}
      <section
        className="back-wrapper"
        style={{ backgroundImage: `url(${getPathAndBackground().bgImage})` }}
      >
        <h6>Home  {getPathAndBackground().path}</h6>
        <h2>{title}</h2>
      </section>
    </div>
  );
};

export default Back;
