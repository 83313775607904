import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import Aos from "aos";

const ContactUs = () => {
  useEffect(() => {
    Aos.init({ disable: window.innerWidth < 768,});
  }, []);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <div className="contact-wrapper">
      <Container className="contact-box">
        <Row>
          <Col lg="6">
            <div
              className="contact-left"
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="1000"
            ></div>
          </Col>
          <Col lg="6">
            <div
              className="contact-right"
              data-aos="fade-up"
              data-aos-delay="120"
              data-aos-duration="1000"
            >
              <div className="contact-head">
                <p className="lightblueBox">Get In Touch</p>
                <h2 className="title">Request Free Consultation</h2>
              </div>
              <div className="home-form-box">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="validationCustom01"
                      className="mb-3"
                    >
                      <Form.Label>Name</Form.Label>
                      <Form.Control required type="text" placeholder="Name" />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>Phone No.</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        placeholder="Phone"
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group
                      as={Col}
                      md="6"
                      controlId="validationCustom03"
                      className="mb-3"
                    >
                      <Form.Label>City</Form.Label>
                      <Form.Control type="text" placeholder="City" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid city.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                      <Form.Label>Email</Form.Label>
                      <Form.Control required type="email" placeholder="Email" />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                      <InputGroup>
                        <Form.Control
                          as="textarea"
                          aria-label="With textarea"
                          placeholder="Your Message here"
                        />
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Button type="submit" className="defaultBtn">
                    Submit
                  </Button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
