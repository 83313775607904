import React, { useEffect } from "react";
import Images from "../../helpers/Images";
import { Container } from "react-bootstrap";
import Aos from "aos";

const WhatWeDo = () => {
  useEffect(() => {
    Aos.init({});
  }, []);
  return (
    <div
      className="what-we-do-wrapper"
      data-aos="fade-up"
      data-aos-delay="120"
      data-aos-duration="1000"
    >
      <Container>
        <div className="heading-part">
          <p className="lightblueBox"> Work Process</p>
          <h2 className="title">How do we works ?</h2>
          <p className="lightTxt">
            Start working with AMOGH that can provide everything you need to
            generate awareness,
            <br /> drive traffic, connect.
          </p>
        </div>
        <div className="what-do-Box-wrapper">
          <div className="what-do-Box box1">
            <img src={Images.whatsection1} alt="what-we-do" />
            <div className="what-do-Box-content">
              <h3>Discussion</h3>
              <p>
                Engage, share, and innovate with our vibrant discussion forums,
                where ideas collide and solutions emerge.
              </p>
            </div>
          </div>
          <div className="what-do-Box box2">
            <img src={Images.whatsection2} alt="what-we-do" />
            <div className="what-do-Box-content">
              <h3>Developing & Testing</h3>
              <p>
                From concept to code, and testing to perfection, we're dedicated
                to developing and refining solutions that exceed expectations.
              </p>
            </div>
          </div>
          <div className="what-do-Box box3">
            <img src={Images.whatsection3} alt="what-we-do" />
            <div className="what-do-Box-content">
              <h3>Deliver</h3>
              <p>
                Delivering excellence is our promise. With precision and care,
                we ensure that every project meets and surpasses expectations.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default WhatWeDo;
