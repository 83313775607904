import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Back from "../../components/Back";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FaCloudUploadAlt } from "react-icons/fa";
import Aos from "aos";

const Career = () => {
  useEffect(() => {
    document.title = 'Amogh | Career'
      },[]);
  useEffect(() => {
    Aos.init({});
  }, []);
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <>
      <Header />
      <Back title={"Career"} />
      <div className="career-wrapper">
        <Container>
          <Row>
            <div className="carrer-inner">
              <div
                className="career-left"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <h3>Current openings</h3>
                <p>
                  We are looking for experienced, professionals, with a niche
                  skills in their expertise.
                </p>
                <ul>
                  <li>Wordpress Developer</li>
                  <li>Magento Developer</li>
                  <li>Shopify Developer</li>
                  <li>Angular Developer</li>
                  <li>Business Analyst</li>
                  <li>DotNet Developer</li>
                  <li>Mean/Mern Developer</li>
                  <li>PHP Developer</li>
                  <li>React Native Developer</li>
                  <li>NodeJs Developer</li>
                  <li>Quality Analyst</li>
                </ul>
              </div>
              <div
                className="career-right"
                data-aos="fade-left"
                data-aos-delay="120"
                data-aos-duration="1000"
              >
                <div className="career-head">
                  <h3>Ready To Sky-Rocket Your Career?</h3>
                  <p>
                    Please provide as much information as possible and we’ll
                    contact you within 24 hours to advise you our next steps
                    forward for the Recruitment.
                  </p>
                </div>
                <div className="form-box">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                        className="mb-3"
                      >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="First Name"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your first name.
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Last Name"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your last name.
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                        className="mb-3"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="email"
                          placeholder="Email"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid email.
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom01"
                      >
                        <Form.Label>Phone No.</Form.Label>
                        <Form.Control
                          required
                          type="number"
                          placeholder="Phone"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid number.
                        </Form.Control.Feedback>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        md="6"
                        controlId="validationCustom03"
                        className="mb-3"
                      >
                        <Form.Label>Experience</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Experience"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid experience.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} md="6" controlId="formGridState">
                        <Form.Label>Apply for</Form.Label>
                        <Form.Select defaultValue="Choose...">
                          <option>Choose...</option>
                          <option>Angular Developer</option>
                          <option>Business Analyst</option>
                          <option>DotNet Developer</option>
                          <option>Magento Developer</option>
                          <option>NodeJs Developer</option>
                          <option>Quality Analyst</option>
                          <option>React Native Developer</option>
                          <option>Shopify Developer</option>
                          <option>Wordpress Developer</option>
                        </Form.Select>
                      </Form.Group>
                    </Row>
                    {/* <Row>
                      <Form.Group
                        as={Col}
                        md="12"
                        controlId="validationCustom03"
                      >
                        <InputGroup>
                          <Form.Control
                            as="file"
                            aria-label="With textarea"
                            placeholder="Your Message here"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Row> */}
                    <Row>
                      <Col>
                        <div className="upload-wrapper">
                          <input
                            className="upload-area"
                            type="file"
                            name="resume"
                          />
                          <i className="icon">
                            <FaCloudUploadAlt />
                          </i>

                          <strong> Upload Resume</strong>
                          <span>
                            doc, xls, pdf, txt, ppt and zip files only
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Button type="submit" className="defaultBtn">
                      Submit
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Career;
