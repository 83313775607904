import React, { useRef, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Images from "../../helpers/Images";
import CountUp from "react-countup";
import useOnScreenHook from "../../helpers/useOnScreenHook";
import Aos from "aos";
import { useNavigate } from "react-router-dom";

const WhoWeAre = () => {
  useEffect(() => {
    Aos.init({});
  }, []);
  const navigate = useNavigate();
  const counterref = useRef(null);
  const isVisible = useOnScreenHook(counterref);
  return (
    <div data-aos="fade-up" data-aos-delay="120" data-aos-duration="1000">
      <Container>
        <Row ref={counterref}>
          <Col lg="6">
            <div className="about-whom-left">
              <img src={Images.about1} alt="about img-fluid" />
            </div>
          </Col>
          <Col lg="6">
            <div className="about-whom-right">
              <div className="about-counter">
                <div className="counter-box">
                  <span className="count">
                    {isVisible && <CountUp delay={0} end={8} />}+
                  </span>
                  <span>
                    Years <br />
                    Experience
                  </span>
                </div>
              </div>
              <div className="about-who-content">
                <h3 className="title">Who we are ?</h3>
                <p>
                  At Amogh, we are a team of dedicated
                  professionals passionate about leveraging technology to drive
                  business success. With expertise in various domains, we strive
                  to empower our clients with innovative solutions tailored to
                  their unique needs. Our commitment to excellence,
                  collaboration, and customer satisfaction defines who we are
                  and what we stand for.
                </p>
                <Button
                  type="submit"
                  className="defaultBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  Get In Touch
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhoWeAre;
