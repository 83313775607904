import React from "react";
import { Container } from "react-bootstrap";

const CommonBlueBox = () => {
  return (
    <div className="blueBox-wrapper">
      <Container>
        <div className="blueBox-inner">
          <p className="lightblueBox">Contact Us</p>
          <h2 className="title">Have Any Query ?</h2>
        </div>
      </Container>
    </div>
  );
};

export default CommonBlueBox;
