import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Back from "../../components/Back";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import Map from "./Map";
import Aos from "aos";

const ContactUs = () => {
  useEffect(() => {
    Aos.init({});
  }, []);
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  useEffect(() => {
    document.title = 'Amogh | Contact Us'
      },[]);
  return (
    <>
      <Header />
      <Back title={"Contact Us"} />
      <div className="contactUs-wrapper">
        <div className="contactUs-inner">
          <Container>
            <Row>
              <Col lg="4">
                <div
                  className="contactUs-left"
                  data-aos="fade-right"
                  data-aos-delay="120"
                  data-aos-duration="1000"
                >
                  <p>LET'S TALK</p>
                  <h3>Speak With Expert Engineers.</h3>
                  <div className="content-box">
                    <div className="icon-box">
                      <i>
                        <FaEnvelope />
                      </i>
                    </div>
                    <div className="txt-box">
                      <span>Email :</span>
                      <a href="mailto:sarvesh@amogh.co.uk">
                        sarvesh@amogh.co.uk
                      </a>
                    </div>
                  </div>
                  <div className="content-box">
                    <div className="icon-box">
                      <i>
                        <FaPhoneAlt />
                      </i>
                    </div>
                    <div className="txt-box">
                      <span>Phone :</span>
                      <a href="tel:+44 7796 002487">+44 7796 002487</a>
                      <a href="tel:+91 9829161718">+91 9829161718</a>
                    </div>
                  </div>
                  <div className="content-box">
                    <div className="icon-box">
                      <i>
                        <FaMapMarkerAlt />
                      </i>
                    </div>
                    <div className="txt-box">
                      <span>Address :</span>
                      <p>107, Navjeevan Chambers, C-Scheme, Jaipur</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="8">
                <div
                  className="contactUs-right"
                  data-aos="fade-left"
                  data-aos-delay="120"
                  data-aos-duration="1000"
                >
                  <div className="contact-head">
                    <p className="lightblueBox">Get In Touch</p>
                    <h2 className="title">Request Free Consultation</h2>
                  </div>
                  <div className="form-box">
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                          className="mb-3"
                        >
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            placeholder="Name"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide your name.
                          </Form.Control.Feedback>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Phone No.</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            placeholder="Phone"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid number.
                          </Form.Control.Feedback>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom03"
                          className="mb-3"
                        >
                          <Form.Label>City</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="City"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid city.
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            required
                            type="email"
                            placeholder="Email"
                          />
                          <Form.Control.Feedback type="invalid">
                            Please provide a valid email.
                          </Form.Control.Feedback>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationCustom03"
                          className="mb-3"
                        >
                          <InputGroup>
                            <Form.Control
                              as="textarea"
                              aria-label="With textarea"
                              placeholder="Your Message here"
                            />
                          </InputGroup>
                        </Form.Group>
                      </Row>
                      <Button type="submit" className="defaultBtn">
                        Submit
                      </Button>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Map />
      <Footer />
    </>
  );
};

export default ContactUs;
