import React, { useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import portfolioData from "../../helpers/PortfolioDetail.json";
import Aos from "aos";
import { isMobile } from "react-device-detect";

const Projects = () => {
  useEffect(() => {
    Aos.init({});
  }, []);
  const getCatogary = (category) => {
    if (isMobile) {
      const mobCategoryarr = category.split(" ");
      return mobCategoryarr[0];
    }
    return category;
  };
  return (
    <>
      <div className="portfolioDetail-wrapper">
        <Tabs
          defaultActiveKey="mobile-apps"
          id="justify-tab-example"
          className="mb-3 tabs-setion"
          justify
          data-aos="zoom-in"
        >
          {portfolioData?.map((item, i) => (
            <Tab
              key={i}
              eventKey={item?.key}
              title={
                <span>
                  {getCatogary(item?.category)}
                  <img
                    className="normal-img"
                    src={`${process.env.PUBLIC_URL}/${item.imgN}`}
                    alt="gfdf"
                  />
                  <img
                    className="active-img"
                    src={`${process.env.PUBLIC_URL}/${item.imgW}`}
                    alt="gfdf"
                  />
                </span>
              }
            >
              {item?.projects?.map((project, index) => (
                <div key={index} className="portfolio-content">
                  <div
                    className="portfolio-left"
                    data-aos="fade-right"
                    data-aos-offset="200"
                    data-aos-delay="120"
                    data-aos-duration="1000"
                  >
                    <h3>{project?.title}</h3>

                    <p>{project?.desc}</p>
                    <div className="language-wrapper">
                      {project?.language?.map((tech, index) => (
                        <div key={index} className="texh-box">
                          <span>{tech?.title}</span>
                        </div>
                      ))}
                    </div>
                    {/* <Button className="defaultBtn">View More</Button> */}
                  </div>
                  <div
                    className="portfolio-right"
                    data-aos="fade-left"
                    data-aos-offset="200"
                    data-aos-delay="120"
                    data-aos-duration="1000"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/${project?.img}`}
                      alt="portfolio"
                    />
                  </div>
                </div>
              ))}
            </Tab>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default Projects;
