import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Banner from "./Banner";
import Services from "./Services";
import About from "./About";
import Technology from "./Technology";
import Project from "./Project";
// import Testimonial from "./Testimonial";
import ContactUs from "./ContactUs";
import Counter from "./Counter";
import SeoTags from "../../components/SeoTags";

const Home = () => {

  return (
    <div className="home-wrapper">
      <SeoTags title="Amogh" description="This is my description"/>
      <Header />
      <Banner />
      <Services />
      <About />
      <Technology />
      <Project />
      {/* <Testimonial /> */}
      <Counter />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Home;
