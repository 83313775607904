import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Images from "../../helpers/Images";
import Aos from "aos";
import { useNavigate } from "react-router-dom";

const Project = () => {
  useEffect(() => {
    Aos.init({ disable: window.innerWidth < 768});
  }, []);
  const navigate = useNavigate();
  return (
    <div className="project-wrapper">
      <Container>
        <Row>
          <div
            className="project-head"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <p className="lightblueBox">Recent Project</p>
            <h2 className="title">Some Finest Projects We Completed</h2>
          </div>
        </Row>
        <Row>
          {/* <div className="project-filter-wrapper">
            <button>All</button>
            <button>Mobile App</button>
            <button>Web Design</button>
            <button>Product Design</button>
          </div> */}
          <div
            className="project-gallery"
            data-aos="fade-up"
            data-aos-delay="100"
            data-aos-duration="1000"
          >
            <div className="gallery-inner">
              <div className="gallery-box">
                <div className="gallery-box-img">
                  <img src={Images.gallery1} alt="gallery" />
                  <div className="gallery-box-content">
                    <div className="content-inner" onClick={()=> navigate('/portfolio')}>
                      <h6>Mobile App</h6>
                      <p>Product Designing</p>
                    </div>
                  </div>
                </div>
                <div className="gallery-box-img">
                  <img src={Images.gallery4} alt="gallery" />
                  <div className="gallery-box-content">
                    <div className="content-inner" onClick={()=> navigate('/portfolio')}>
                      <h6>Mobile App</h6>
                      <p>Product Designing</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gallery-box">
                <div className="gallery-box-img">
                  <img src={Images.gallery2} alt="gallery" />
                  <div className="gallery-box-content">
                    <div className="content-inner" onClick={()=> navigate('/portfolio')}>
                      <h6>Mobile App</h6>
                      <p>Product Designing</p>
                    </div>
                  </div>
                </div>
                <div className="gallery-box-img">
                  <img src={Images.gallery5} alt="gallery" />
                  <div className="gallery-box-content">
                    <div className="content-inner" onClick={()=> navigate('/portfolio')}>
                      <h6>Mobile App</h6>
                      <p>Product Designing</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gallery-box">
                <div className="gallery-box-img">
                  <img src={Images.gallery3} alt="gallery" />
                  <div className="gallery-box-content">
                    <div className="content-inner" onClick={()=> navigate('/portfolio')}>
                      <h6>Mobile App</h6>
                      <p>Product Designing</p>
                    </div>
                  </div>
                </div>
                <div className="gallery-box-img">
                  <img src={Images.gallery6} alt="gallery" />
                  <div className="gallery-box-content">
                    <div className="content-inner" onClick={()=> navigate('/portfolio')}>
                      <h6>Mobile App</h6>
                      <p>Product Designing</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Project;
