import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Back from "../../components/Back";
import { Container, Row } from "react-bootstrap";
import CommonBlueBox from "../../components/CommonBlueBox";
import ContactUs from "../Home/ContactUs";
import Projects from "./Projects";
import Aos from "aos";

const Portfolio = () => {
  useEffect(() => {
    Aos.init({});
  }, []);
  useEffect(() => {
    document.title = 'Amogh | Portfolio'
      },[]);
  return (
    <>
      <Header />
      <Back title={"Portfolio"} />
      <div className="portfolio-wrapper">
        <div className="portfolio-inner">
          <Container>
            <Row>
              <div
                className="portfolio-head"
                data-aos="fade-up"
              >
                <p className="lightblueBox">Recent Project</p>
                <h2 className="title">Some Finest Projects We Completed</h2>
              </div>
            </Row>
            <Row>
              {/* <div className="portfolio-filter-wrapper">
                <Button
                  className={activeButton === "Mobile Apps" ? "active" : ""}
                  onClick={() => handleButtonClick("Mobile Apps")}
                >
                  <img
                    className="active-img"
                    src={Images.serviceW3}
                    alt="tabs"
                  />
                  <img
                    className="normal-img"
                    src={Images.service3}
                    alt="tabs"
                  />
                  <span>Mobile Apps</span>
                </Button>
                <Button
                  className={activeButton === "Web Projects" ? "active" : ""}
                  onClick={() => handleButtonClick("Web Projects")}
                >
                  <img
                    className="active-img"
                    src={Images.serviceW2}
                    alt="tabs"
                  />
                  <img
                    className="normal-img"
                    src={Images.service2}
                    alt="tabs"
                  />
                  <span>Web Projects</span>
                </Button>
                <Button
                  className={
                    activeButton === "Software Projects" ? "active" : ""
                  }
                  onClick={() => handleButtonClick("Software Projects")}
                >
                  <img
                    className="active-img"
                    src={Images.serviceW1}
                    alt="tabs"
                  />
                  <img
                    className="normal-img"
                    src={Images.service1}
                    alt="tabs"
                  />
                  <span>Software Projects</span>
                </Button>
                <Button
                  className={
                    activeButton === "Digital Marketing" ? "active" : ""
                  }
                  onClick={() => handleButtonClick("Digital Marketing")}
                >
                  <img
                    className="active-img"
                    src={Images.serviceW4}
                    alt="tabs"
                  />
                  <img
                    className="normal-img"
                    src={Images.service4}
                    alt="tabs"
                  />
                  <span>Digital Marketing</span>
                </Button>
              </div> */}
              <Projects />
            </Row>
          </Container>
        </div>
        <CommonBlueBox />
        <ContactUs />
      </div>
      <Footer />
    </>
  );
};

export default Portfolio;
