import React,{useEffect} from "react";
import Aos from "aos";

const Map = () => {
  useEffect(() => {
    Aos.init({});
  }, []);
  return (
    <div
      className="map-wrapper"
      data-aos="zoom-in"
      data-aos-delay="120"
      data-aos-duration="1000"
    >
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2486.896968767325!2d-1.0327007241493404!3d51.44168601550275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48769be5e79a6529%3A0x130920600117b749!2s3%20Brookside%2C%20Calcot%2C%20Reading%20RG31%207PJ%2C%20UK!5e0!3m2!1sen!2sin!4v1710134268934!5m2!1sen!2sin"
        width="100%"
        height="350"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        title="map"
      ></iframe>
    </div>
  );
};

export default Map;
