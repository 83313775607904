import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Aos from "aos";
import { useNavigate } from "react-router-dom";

const WorkProcess = () => {
  useEffect(() => {
    Aos.init({});
  }, []);
  const navigate = useNavigate();
  return (
    <div className="workProcess-wrapper">
      <Container>
        <div className="workProcess-inner">
          <Row>
            <Col lg="4">
              <div
                className="workProcess-left"
                data-aos="fade-right"
                data-aos-delay="120"
                data-aos-duration="1000"
              >
                <p>Working Process</p>
                <h3>Our Working Process - How We Work For Our Customers</h3>
                <Button
                  className="defaultBtn"
                  onClick={() => navigate("/contact-us")}
                >
                  Contact Us
                </Button>
              </div>
            </Col>
            <Col lg="8">
              <div
                className="workProcess-right"
                data-aos="zoom-in"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                <div className="content-box">
                  <h2>01.</h2>
                  <h3>Discovery</h3>
                  <p>
                    We deeply understand your goals, audience, and needs through
                    research, guiding our strategy with key insights for
                    success.
                  </p>
                </div>
                <div className="content-box">
                  <h2>02.</h2>
                  <h3>Planning</h3>
                  <p>
                    Our tailored plan maps design, development, and deployment,
                    aligning with your objectives for a seamless process.
                  </p>
                </div>
                <div className="content-box">
                  <h2>03.</h2>
                  <h3>Execute</h3>
                  <p>
                    Experts bring your vision to life, prioritizing
                    communication to craft a functional, appealing website.
                  </p>
                </div>
                <div className="content-box">
                  <h2>04.</h2>
                  <h3>Deliver</h3>
                  <p>
                    After rigorous testing, we launch your site across devices,
                    offering ongoing support for continued success.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default WorkProcess;
