import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import AppRoutes from "./AppRoutes";
import ScrollToTop from "react-scroll-to-top";
function App() {
  return (
    <>
     <HelmetProvider>
      <AppRoutes />
      <div className="scroll-top">
        <ScrollToTop smooth color="#fff" />
      </div>
      </HelmetProvider>
    </>
  );
}

export default App;
