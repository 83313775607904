import React, { useEffect } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import DetailsData from "../../helpers/ServiceDetails.json";
import Aos from "aos";

const Services = () => {
  useEffect(() => {
    Aos.init({ disable: window.innerWidth < 768,});
  }, []);
  return (
    <div className="home-service-wrapper">
      <Container>
        <Row>
          <div
            className="home-service-head"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1000"
          >
            <p className="lightblueBox">core services</p>
            <h2>
              We Are Offering All Kinds of
              <br /> IT Solutions Services
            </h2>
          </div>
        </Row>
        <div className="home-service-box-wrapper">
          {DetailsData?.map((item, i) => (
            <div
              key={`services-${i}`}
              className="home-service-box"
              data-aos="fade-up"
              data-aos-delay="70"
              data-aos-duration="1000"
            >
              <div className="home-service-box-img">
                <img src={`${process.env.PUBLIC_URL}/${item.img}`} alt="service_img" />
              </div>
              <div className="home-service-box-title">{item?.title}</div>
              <div className="home-service-box-desc">
                <p>{item?.short_desc}</p>
              </div>
              <div className="home-service-box-btn">
                <Link to={`/services/${item.key}`} state={item}>
                  <Button variant="outline-primary">View More</Button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Services;
