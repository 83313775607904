import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import DetailsData from "../../helpers/ServiceDetails.json";
import Aos from "aos";

const ServiceCard = () => {
  useEffect(() => {
    Aos.init({});
  }, []);
  const location = useLocation();
  const path = location.pathname;
  return (
    <div className="services-card-section">
      <Container>
        <div
          className="services-card-head"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <p className="lightblueBox">Core Services</p>
          <h2 className="title">Solutions for Businesses</h2>
        </div>

        <div className="service-card-box-wrapper">
          {DetailsData?.map((item, i) => (
            <div
              key={`services-${i}`}
              className="service-card-box"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              <div className="service-box-img">
                <img className="img-normal" src={`${process.env.PUBLIC_URL}/${item.img}`} alt="service_img" />
                <img className="img-white" src={`${process.env.PUBLIC_URL}/${item.img2}`} alt="service_img" />
                {/* <p className="numbring">{`0${(i+1)}`}</p> */}
              </div>
              <div className="service-box-title">{item?.title}</div>
              <div className="service-box-desc">
                <p>{item?.short_desc}</p>
              </div>
              <div className="service-box-btn">
                <Link to={`${path}/${item.key}`} state={item}>
                  <Button className="defaultBtn">More Details</Button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default ServiceCard;
